import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import { seoData } from "../shared/seoData"
import MentoringOffering from "../components/Mentoring/MentoringOffering"
import { TeamMembersData } from "../shared/constants"
import { PageTitles } from "../shared/constants"

export default class MasteringJavascript extends React.Component {
  render() {
    const seo = seoData.masteringJavaScript
    return (
      <>
        <SEO
          title={seo.title}
          description={seo.metaDescription}
          pathname={seo.pathname}
        />
        <Layout selectedTabValue={false}>
          <MentoringOffering title={PageTitles.masteringJavaScript.title} instructors={TeamMembersData} topics={topics}/>
        </Layout>
      </>
    )
  }
}

const topics = [
  "Fundamentals of JavaScript (types, conditionals, loops, and more)",
  "JavaScript Collections (Arrays, Set, Map, Object, and more)",
  "Working knowledge of Scopes, Coercion, Hoisting, Equality in JavaScript",
  "Working knowledge of JavaScript Modules and IIFEs",
  "Fundamentals of JavaScript Classes and Inheritance",
  "Debugging and working with Exceptions",
  "Working knowledge of Functions and Functional Programming",
  "Handling async JavaScript using Callbacks, Promises, and more",
  "Working knowledge of modern JavaScript ES6, ES7, ES8, ES9, and ES10",
  "Working with Browser API (events and data storage)",
]
